import React from 'react'
import IconLink from '../components/icon-link'
import LinkHover from '../components/link-hover'

import styles from './bio-blurb.module.css'

const contactLinks = [
  {
    name: 'linkedin',
    url: 'https://linkedin.com/in/cameronscottbrown'
  },
  {
    name: 'github',
    url: 'https://github.com/cameronsb'
  },
  // {
  //   name: 'codepen',
  //   url: 'https://codepen.io/csbrown'
  // },
  {
    name: 'mail',
    url: 'mailto:cameron.scott.brown@gmail.com'
  }
]

function BioBlurb (props) {
  const iconLinks = contactLinks.map((contact) => {
    return (
      <LinkHover key={contact.url}>
        <IconLink url={contact.url} symbol={contact.name} />
      </LinkHover>
    )
  })

  return (
    <section className={styles.wrapper}>
      { iconLinks }
    </section>
  )
}

export default BioBlurb
