import React from 'react'
import Icon from './icon'

import styles from './icon-link.module.css'

const IconLink = ({url, symbol}) => (
  <a className={styles.wrapper} href={url} rel='noopener noreferrer' target='_blank'>
    <Icon className={styles.icon} symbol={symbol} />
  </a>
)

export default IconLink
