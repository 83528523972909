import React from 'react'
import HamburgerIcon from './hamburger'
import LinkedInIcon from './linkedin'
import GitHubIcon from './github'
import CodepenIcon from './codepen'
import MailIcon from './mail'

function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    case 'github':
      return <GitHubIcon />
    case 'codepen':
      return <CodepenIcon />
    case 'linkedin':
      return <LinkedInIcon />
    case 'mail':
      return <MailIcon />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
