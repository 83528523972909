import {Link} from 'gatsby'
import React from 'react'
import {cn} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'

import styles from './project-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function ProjectPreview (props) {
  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      {props.mainImage && props.mainImage.asset && (
        <div className={styles.leadMediaThumb}>
          <img
            src={imageUrlFor(props.mainImage)
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={props.mainImage.alt}
          />
          <div className={styles.imageOverlay}>
            <p>{props.emoji || `👨‍💻`}</p>
          </div>
        </div>
      )}
      <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
      {props._rawExcerpt && (
        <div className={styles.excerpt}>
          <BlockText blocks={props._rawExcerpt} />
        </div>
      )}
    </Link>
  )
}

export default ProjectPreview
