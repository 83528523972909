import React, {useState} from 'react'

import styles from './site-header.module.css'

const SiteHeader = (props) => {
  const [waved, setWaved] = useState(false)

  return (
    <header
      onMouseEnter={() => setWaved(!waved)}
      onMouseLeave={() => setWaved(!waved)}>
      <h1 className={styles.heading}>
        <span className={waved ? styles.wave : undefined}>👋</span> Hello and welcome!</h1>
      <h2 className={styles.subheading}>My name's Cam, and I build stuff on the web. <span className={styles.nerd}>👨‍💻</span></h2>
    </header>
  )
}

export default SiteHeader
