import React from 'react'

import styles from './link-hover.module.css'

const LinkHover = ({blocks, ...props}) => {
  return (
    <span className={styles.spanimation}>
      { props.children }
    </span>
  )
}

export default LinkHover
