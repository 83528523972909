import React from 'react'

const fillColor = `#fff`

const LinkedInIcon = () => (
  <svg
    style={{
      height: 32 + 'px',
      enableBackground: 'new 0 0 64 64'
    }}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 64 64'
  >
    <path d='M52,64H12C5.4,64,0,58.6,0,52V12C0,5.4,5.4,0,12,0h40c6.6,0,12,5.4,12,12v40C64,58.6,58.6,64,52,64z' />
    <path
      style={{fill: fillColor}}
      d='M7.5,23.3h10.3v33.3H7.5V23.3z M12.7,6.7c3.3,0,6,2.7,6,6.1c0,3.3-2.7,6.1-6,6.1s-6-2.7-6-6.1 C6.7,9.4,9.4,6.7,12.7,6.7' />
    <path
      style={{fill: fillColor}}
      d='M24.4,23.3h10v4.5h0.1c1.4-2.7,4.8-5.4,9.8-5.4c10.5,0,12.4,6.9,12.4,15.9v18.3H46.4V40.4 c0-3.8-0.1-8.9-5.3-8.9c-5.3,0-6.2,4.2-6.2,8.6v16.6H24.5V23.3H24.4z' />
  </svg>

)

export default LinkedInIcon
